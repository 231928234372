:export {
  colorHandleLine: #969696;
  colorCurveLine: black;
  colorRow: #f2f2f2;
  colorBackground: white;
}

.root {
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.wrap {
  position: relative;

  .bg {
    position: absolute;
    top: 0;
    bottom: 0;
    background: #fafafa;
  }
}

.plane {
  position: absolute;
  left: 0;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
}

.curve {
  position: relative;
}

.handle {
  position: absolute;
  border-radius: 50%;
  box-sizing: content-box;
  border: 0;
  padding: 0;
  outline: 0;
  width: 16px;
  height: 16px;
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none;
  transform: translate(-50%, -50%);
  transition: box-shadow 100ms ease-out;

  &.active {
    box-shadow: 0 0 0 4.5px rgba(white, 0.7), 0 0 0 4px currentColor;
  }

  &.fixed {
    cursor: pointer;
    border: 1px solid #969696;
    background-color: white;
  }

  &.origin {
    border: 1px solid #969696;
    background-color: white;
  }

  &.lineEnd {
    border: 1px solid #969696;
    background-color: white;
  }

  &.start {
    color: #f08;
    background-color: #f08;
  }

  &.end {
    color: #0ab;
    background-color: #0ab;
  }
}
