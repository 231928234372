.disabled {
  cursor: not-allowed;
}

.overflow {
  word-break: break-all;
}

.hoverable:hover {
  text-decoration: underline;
}
