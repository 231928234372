.node {
  margin: 0 auto;
  max-width: 45em;
  position: relative;
}

.delete-button {
  position: absolute;
  right: var(--eds-space-4);
  top: var(--eds-space-8);
}

.node-name input {
  font: var(--eds-type-title-4);
}
