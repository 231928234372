.output-table {
  margin-top: var(--eds-space-16);
}

.user-run-table-cell {
  border-bottom: 1px solid var(--eds-color-grey-3);
}

.eds-tag {
  display: inline-flex;
  align-items: center;
  gap: var(--eds-space-8);
  border-radius: var(--eds-radii-4);
  padding: var(--eds-space-8);
  font: var(--eds-type-subtitle-1);
  min-height: 40px;
}

.eds-tag.blue {
  background-color: var(--eds-color-blue-05);
  color: var(--eds-color-blue-90);
}

.eds-tag.green {
  background-color: var(--eds-color-green-05);
  color: var(--eds-color-green-90);
}

.eds-tag.yellow {
  background-color: var(--eds-color-yellow-05);
  color: var(--eds-color-yellow-90);
}
