.back-button {
  margin-top: 5px;
}

.value-cell {
  word-break: break-all;
  white-space: pre-wrap;
}

.disabled {
  cursor: not-allowed;
}
