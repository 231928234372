.field-element {
  border: 1px solid var(--eds-color-background-default);
  border-radius: var(--eds-radii-4);
  cursor: pointer;
  margin-bottom: var(--eds-space-32);
  padding: var(--eds-space-8) var(--eds-space-4);
  position: relative;
}

.field-element:hover {
  background-color: var(--eds-color-background-hover);
}

.field-element input {
  cursor: pointer;
}

.field-element label {
  cursor: pointer;
  min-height: 24px;
}

.error {
  border-color: var(--eds-color-border-danger);
}

.selected {
  background-color: var(--eds-color-background-active);
  border-color: var(--eds-color-border-focus);
}

.delete-button {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
