.result-cell {
  vertical-align: top;
}

.result-table-wrap {
  overflow-x: scroll;
}

.input-cell {
  cursor: pointer;
}

.result-row {
  cursor: pointer;
}
