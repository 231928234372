.card-button:hover {
  cursor: pointer;
  box-shadow: var(--eds-elevation-shadow-4);
}

.step-card {
  margin-bottom: var(--eds-space-12);
  padding-top: var(--eds-space-16);
  padding-bottom: var(--eds-space-16);
  min-width: 240px;
  box-shadow: var(--eds-elevation-shadow-2);
  background-color: var(--eds-color-background-active);
}
