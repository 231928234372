.designer-layout {
  display: grid;
  grid-template-columns: 1fr 400px;
  grid-template-rows: auto auto auto 1fr;
  grid-template-areas:
    'breadcrumbs breadcrumbs'
    'workflow-steps workflow-steps'
    'workflow-name workflow-name'
    'designer settings';
  gap: 16px;
  height: calc(100vh - 64px); /* 64 pixels of margin apps/composure/src/app/app.tsx */
}

.designer {
  grid-area: designer;
}

.breadcrumbs {
  grid-area: breadcrumbs;
}

.workflow-name {
  grid-area: workflow-name;
}

.mt-10 {
  margin-top: 10px;
}

.workflow-name--input input {
  background-color: #f5f5f5;
  font: var(--eds-type-title-2);
}

.workflow-steps {
  grid-area: workflow-steps;
}

.settings {
  grid-area: settings;
  background-color: var(--eds-color-background-default);
  padding: var(--eds-space-24);
  overflow-y: auto;
}
